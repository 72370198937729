<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs">
      <DefaultBtn titleButton="Criar" icon="add" />
    </Mural>
    <main>
      <div class="row col-12">
        <label class="title-group col-12">Nome do grupo</label>
        <q-input
          rounded
          class="col-5"
          outlined
          label="Digite um nome para o grupo.."
          color="default-pink"
          bg-color="grey-3"
          v-model="text"
        />
      </div>
      <div class="container-options col-12 q-pa-md">
        <div class="row col-12 q-pa-md">
          <label class="title-options col-11 q-pl-md">Permissões</label>
          <label class="title-status col q-pl-sm">Status</label>
        </div>
        <div class="col-12 q-pt-sm">
          <q-list>
            <q-expansion-item
              group="somegroup"
              class="btn-drop"
              label="Feed"
              default-opened
              header-class="text-black"
            >
              <div class="column">
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Postar</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Comentar</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Reagir</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
              </div>
            </q-expansion-item>
          </q-list>
        </div>
        <div class="col-12 q-pt-sm">
          <q-list>
            <q-expansion-item
              group="somegroup"
              default-opened
              class="btn-drop"
              label="Trilhas"
              header-class="text-black"
            >
              <div class="column">
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Jornada do Cliente</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Vendas no Varejo</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
                <div class="chat-drop row col-12 q-pa-sm">
                  <label class="title-content col-11">Employer Branding</label>
                  <q-toggle
                    class="content-toggle col"
                    color="default-pink"
                    v-model="value"
                  />
                </div>
              </div>
            </q-expansion-item>
          </q-list>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import { ref } from "vue";

export default {
  name: "EditGroup",
  components: {
    Mural,
    DefaultBtn,
  },
  setup() {
    let breadcrumbs = ref(["Novo grupo em tutores"]);
    return {
      value: ref(true),
      breadcrumbs,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-options {
  overflow-y: auto;
  height: 86%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 30px 0px;
}

.title-group {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.title-options {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.title-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #9c9c9c;
}

.title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.content-options {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 20px;
  flex: none;
  order: 1;
  height: 50px;
  flex-grow: 0;
  margin: 10px 0px;
}
.content-options:hover {
  background-color: #f3f1f1;
  border: 1px solid #fe5268;
  transition-delay: 0.2ms;
}
.chat-drop {
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.btn-drop {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
}
//.btn-drop:hover{
//  background-color: #FFF8EE;
//  border: 1px solid #FE5268;
//  transition-delay: 0.2ms;
//}
</style>